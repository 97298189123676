const translations = {
    'en': {
        'ANOTHER LINK': 'ANOTHER LINK'
    },
    'de': {
        'ANOTHER LINK': 'EIN ANDERER LINK'
    },
    'fr': {
        'ANOTHER LINK': 'Drugi link'
    }
};

export default translations;
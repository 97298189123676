

import React, { Component } from 'react';
import Routes from './routes'
import socketIO from 'socket.io-client';
import popup_bg from './assets/images/popupbg.png';
import coupon from './assets/images/coupon.png';
import logo from './assets/svg/logo.svg';
import DocumentMeta from 'react-document-meta';


Number.prototype.formatPrice = function (currency) {
  let price = this;
  price = price * currency.exchangeRate;
  let dec_point = ',';
  let thousands_sep = '.';

  var parts = price.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point) + ' ' + currency.code;
}

String.prototype.formatPrice = function (currency) {
  let price = parseFloat(this);
  price = price * currency.exchangeRate;
  let dec_point = ',';
  let thousands_sep = '.';

  var parts = price.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point) + ' ' + currency.code;
}


class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.translate = this.translate.bind(this);
    this.updateBreadcrumb = this.updateBreadcrumb.bind(this);
    this.setUserData = this.setUserData.bind(this);
    this.allowCookies = this.allowCookies.bind(this);
    this.showInfoMessage = this.showInfoMessage.bind(this);
    this.hideInfoMessage = this.hideInfoMessage.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    window.googleMapsCallback = this.googleMapsCallback;
    this.setCurrency = this.setCurrency.bind(this);


    this.state = {
      instagramPhotos: [],
      lang: 'de',
      breadcrumb: [],
      categories: [],
      uData: null,
      cartCount: 0,
      newestProducts: [],
      popularProducts: [],
      offerProducts: [],
      topProducts: [],
      brands: {},
      news: [],
      banners: [],
      infoMessages: {

      },
      currency: {
        code: 'RSD',
        exchangeRate: 1
      },
      pages: {
        'o-nama': {},
        'kako-naruciti': {},
        'privacy-policy': {},
        'terms-and-conditions': {},
        'narucivanje-i-dostava': {},
        'placanje': {},
        'povrat-proizvoda-i-reklamacije': {}
      }
    }
  }
  addToCart = (product) => {

    if (window.location.pathname.indexOf('/product') == -1) {
        this.state.socketIOClient.once('addToCart', (data) => {
          console.log(data);
          if (data.successful) {
            this.showInfoMessage(this.translate('Artikal je uspešno dodat u korpu.'));
            this.state.socketIOClient.emit('cartInfo', {});
          } else {
            this.showInfoMessage(this.translate('Nema dovoljno na stanju.'), true);

          }
        });
      }

      this.state.socketIOClient.emit('addToCart', { _id: product._id, quantity: 1, package: product.package[0] });
  }

  setCurrency(currency){
    this.setState({
      currency
    })
  }

  showInfoMessage(text, error) {
    console.log(this.state.infoMessages);
    let messages = this.state.infoMessages;
    let idx = Date.now().toString();
    messages[idx] = {
      idx: idx,
      message: text,
      error: error
    };

    this.setState({
      infoMessages: messages
    }, () => {
      this.forceUpdate();
      setTimeout(() => {
        this.hideInfoMessage(idx);
      }, 3000);
    });



  }

  hideInfoMessage(idx) {
    let messages = this.state.infoMessages;
    if (!messages[idx])
      return;
    messages[idx].animate = true;
    this.setState({
      infoMessages: messages
    }, () => {
      setTimeout(() => {


        let messages = this.state.infoMessages;
        delete messages[idx];
        this.setState({
          infoMessages: messages
        })
      }, 1000);
    })
  }

  hidePopup() {
    localStorage.popup = Math.floor(Date.now() / 1000);
    console.log(true)
    this.setState({
      popup: null
    })
  }

  allowCookies() {
    localStorage.allowCookies = true;
    this.setState({
      cookies: true
    });
  }


  updateBreadcrumb(bcrumb) {
    this.setState({
      breadcrumb: bcrumb
    });
  }

  googleMapsCallback() {
    this.setState({
      _googleMapsLoaded: true
    });
  }

  setUserData(data) {
    this.setState({
      uData: data
    });

    localStorage.uData = JSON.stringify(data);

  }

  componentDidMount() {

    if (localStorage.popup) {
      if (parseInt(localStorage.popup) + 24 * 60 * 60 < Math.floor(Date.now() / 1000)) {
        //alert(true);
        this.setState({
          popup: true
        })
      }
    } else {
      this.setState({
        popup: true
      })
    }

    if (localStorage.allowCookies) {
      this.setState({
        cookies: true
      });
    }

    let socket = socketIO('https://socket.bluzeikosulje.com/');

    console.log(socket);
    this.setState({
      socketIOClient: socket
    });


    if (localStorage.uData) {
      let uData = JSON.parse(localStorage.uData);

      if (uData && uData.User && uData.User.EMail && uData.User.pk)
        socket.emit("userVerify", { email: uData.User.EMail, pk: uData.User.pk });
    }


    socket.on('cartInfo', (data) => {
      this.setState({
        cartCount: data.count
      })
    })

    socket.on('userVerify', (data) => {
      console.log(data);
      if (data.successful) {
        this.setUserData(data.user);
      }

      socket.emit('cartInfo', {});

    });
    


    
    socket.on('fetchBanners', (data) => {
      console.log(data);
      this.setState({
          banners: data
      })
  });


    socket.on('fetchCategories', (data) => {
      console.log(data);
      this.setState({
        categories: data
      });
    });

    socket.on('fetchNews', (data) => {
      console.log(data);
      this.setState({
        news: data
      });
    });
    socket.on('fetchBrands', (data) => {
      console.log(data);
      let brands = {};
      for(let i=0;i<data.length;i++){
        brands[data[i]._id] = data[i];
      }

      this.setState({
        brands
      });
    });



    socket.on('fetchPopup', (data) => {
      console.log(data);
      this.setState({
        popupData: data
      });
    });
    socket.on('fetchPages', (data) => {
      console.log(data);
      this.setState({
        pages: data
      });
    });



    socket.on('userLogout', (data) => {
      socket.emit('cartInfo', {});
    })
    socket.emit('fetchBanners', {});

    socket.emit('fetchCategories', {});
    
    socket.emit('fetchBrands', {});

    socket.emit('fetchNews', {});
    socket.emit('fetchPages', {});

    socket.on('siteData', (data) => {
      console.log(data);
      this.setState({
        siteData: data
      });
    });

    
    fetch('https://www.instagram.com/bluzeikosulje/', {
      headers: { 'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.80 Safari/537.36' }
    }).then((res) => res.text()).then((source) => {
      try {
        var json = JSON.parse(source.split('window._sharedData =')[1].split(';</script>')[0]);
        let edges = json.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges;
        console.log(edges)
        let instagramPhotos = edges.map((item) => {
          return {
            preview: item.node.thumbnail_resources[0].src,
            link: 'https://instagram.com/p/' + item.node.shortcode
          }
        });
        this.setState({
          instagramPhotos: instagramPhotos.slice(0, 6)
        })
      } catch (e) {

      }



    })


  }


  translate(text) {
    return text;
  }

  render() {

    const meta = {
      title: 'Bluze & Košulje',
      meta: {
          charset: 'utf-8',
          name: {
              'og:title': 'Bluze & Košulje',
              'og:image': 'https://files.bluzeikosulje.com/social.png'
          }
      }
  };


    return (
      <div>
                        <DocumentMeta {...meta}>
                </DocumentMeta>

        <Routes
          translate={this.translate}
          updateBreadcrumb={this.updateBreadcrumb}
          setUserData={this.setUserData}
          allowCookies={this.allowCookies}
          showInfoMessage={this.showInfoMessage}
          hideInfoMessage={this.hideInfoMessage}
          hidePopup={this.hidePopup}
          setCurrency={this.setCurrency}
          addToCart={this.addToCart}

          {...this.state}
        />
        <div className="pop-up-messages">
          {
            Object.values(this.state.infoMessages).map((item, idx) => {
              console.log(item);
              return (
                <div className={item.animate ? 'hide-message ' : ''} key={idx} onClick={() => this.hideInfoMessage(item.idx)}>
                  <i className="mdi mdi-close hide" />

                  <p className={item.error ? 'error' : ''}>{item.error ? <i className="mdi mdi-close" ></i> : null}{item.message}</p>
                </div>
              )
            })
          }

        </div>

        {this.state.popup && this.state.popupData ?
          <div className="popup">
            <img src={this.state.popupData.Background} className="overlay" />
            <div className="content">
              <img src={this.state.popupData.Image} className="coupon" />
              <h2>{this.state.popupData.Title}</h2>
              <h3>{this.state.popupData.Subtitle}</h3>
              <h6>{this.state.popupData.Text}</h6>
              <button className="button" onClick={() => this.hidePopup()}>{this.translate('BEGINN MIT DEM EINKAUF')}</button>
            </div>
          </div>
          : null
        }
            <div className="loader">
                <img src={logo} />
            </div>

      </div>

    );

  }

}

export default App;
